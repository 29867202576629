import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";

interface EmailProps {
  email: string;
  setEmail: (email: string) => void;
  onNext: () => void;
  onPrev?: () => void;

}

const Email = ({ onNext, email, setEmail }: EmailProps) => {

  return (
    <>
      <div className="font-sans lg:px-16 md:px-16 px-12 py-10">
        {/* <Link to="/signup">
          <button className="bg-atlas_dark-brown rounded-full p-2 ">
            <IoArrowBack size={20} />
          </button>
        </Link> */}
        <h3 className="text-4xl text-atlas_black dark:text-white pb-2">Reset password</h3>
        <p className="font-normal text-opacity-70 text-sm text-atlas_gray dark:text-atlas_darkslate">
          Input your registered Email address
        </p>
      </div>
      <form className="lg:px-16 md:px-16 px-12 font-sans">
        <div>
          <label
            htmlFor="email"
            className="text-atlas_black dark:text-white font-normal text-lg block"
          >
            Email
            <span className="text-red-500">*</span>
          </label>
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-3 py-3 w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:ring-0 rounded-md focus:outline-none dark:border-0 border border-atlas_darkslate"
              placeholder="someone@email.com"
              required
              inputMode="email"
            />
          </div>
        </div>
      </form>
      <div className="lg:px-16 md:px-16 px-12">
        <button
          className="btn py-3 mt-10"
          onClick={onNext}
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default Email;
