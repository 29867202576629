import React from 'react'

const Sell = () => {
  return (
    <div className='w-full border-b border-l border-r border-atlas_orange py-10'>
   <form className="font-sans ml-10">
        <label className="text-white mb-1 text-sm">I will spend</label>
        <div className="overflow-hidden bg-atlas_gray border border-solid mt-3 mb-6 border-white/50 text-white rounded-lg w-2/6">
          <input
            className="w-3/5 bg-transparent focus:outline-none py-2 px-2"
            type="number"
            placeholder="Amount"
          />
          <select id="crypto" className="border-none focus:outline-none flex-shrink-0 z-10 inline-flex items-center py-2.5 text-sm font-medium text-left bg-atlas_gray text-white ">
          {/* <option selected>NGN</option> */}
          <option value="usd">BTC</option>
          <option value="gbp">ETH</option>
          <option value="ngn">SOL</option>
      </select>
        </div>

        <label className="text-white mb-1 text-sm">I want to receive</label>
        <div className="overflow-hidden bg-atlas_gray border border-solid  mt-3 mb-6 border-white/50 text-white rounded-lg w-2/6">
          <input
            className="w-3/5 bg-transparent focus:outline-none py-2 px-2"
            type="number"
            placeholder="Amount"
          />
          <select id="currency" className="border-none focus:outline-none flex-shrink-0 z-10 inline-flex items-center py-2.5 text-sm font-medium text-left bg-atlas_gray text-white ">
          {/* <option selected>NGN</option> */}
          <option value="usd">USD</option>
          <option value="gbp">GBP</option>
          <option value="ngn">NGN</option>
      </select>
        </div>

        {/* //Drop dowm menu */}
        <select id="payment" className="px-2 py-3 rounded-lg border border-solid my-3 border-white/50 focus:outline-none flex-shrink-0 z-10 inline-flex items-center text-sm font-medium text-left bg-atlas_gray text-white ">
          <option selected>Select Payment Method</option>
          <option value="usd">PayPal</option>
          <option value="gbp">Bank Transfer</option>
          <option value="ngn">Debit/Credit Card</option>
      </select>

        
      </form>
      <div className="flex justify-center my-4">
        <button className="w-4/5 bg-atlas_orange text-white p-3 rounded-lg">Sell</button>
      </div>
    </div>
  )
}

export default Sell