import React from 'react'
import Ellipse from '../../assets/Ellipse.svg'
import { MdOutlineCurrencyExchange } from "react-icons/md";
import { GoArrowSwitch } from "react-icons/go";


const Trade = () => {
  return (
    <div>
      <div className='p-3'>Trade</div>
      <div className=" py-10">

        <div className="border border-solid border-white/50 mx-10 mb-9 cursor-pointer hover:bg-atlas_orange rounded-lg p-3">
          <div className="flex justify-between">
            <div>
              <div className="flex items-center">
                <MdOutlineCurrencyExchange className='rounded-full' />
                <p className='pl-3'>Fiat Trading</p>
              </div>
              <p className="text-sm font-normal text-white/70 pl-7">
                Trade digital assets directly via various payment methods
              </p>
            </div>
          </div>
        </div>

        <div className="border border-solid border-white/50 mx-10 mb-9 cursor-pointer hover:bg-atlas_orange rounded-lg p-3">
          <div className="flex justify-between">
            <div className="">
              <div className="flex items-center">
              <GoArrowSwitch className='rounded-full' />
              <p className='pl-3'>P2P Trading</p>
              </div>
              <p className="text-sm font-normal text-white/70 pl-7">
                Trade digital assets directly via various payment methods
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-4">
          <button className="w-4/5 bg-atlas_orange text-white p-3 rounded-lg">Trade</button>
        </div>
      </div>
    </div>
  )
}

export default Trade