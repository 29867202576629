import React from "react";
import boxicon from "../../assets/boxicon.svg";
import ngnpic from "../../assets/ngn.svg";
import usdpic from "../../assets/usd.svg";
import gbppic from "../../assets/gbp.svg";
import kespic from "../../assets/kes.svg";
import Ellipse from "../../assets/Ellipse.svg";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { Link } from "react-router-dom";
import CurrencyDropdown from "../CurrencyDropdown";

const RightSidebar = () => {
  const data = [
    {
      title: "NGN",
      pic: ngnpic,
      description: "Nigerian Naira",
      symbol: "₦",
      amount: "NGN 1700/USD",
      status: "decrease",
      percentage: 4.2,
    },
    {
      title: "GBP",
      pic: gbppic,
      description: "Great British Pounds",
      symbol: "£",
      amount: "GBP 1700/USD",
      status: "increase",
      percentage: 4.2,
    },
    {
      title: "USD",
      pic: usdpic,
      description: "US Dollar",
      symbol: "$",
      amount: "USD 1/USD",
      status: "increase",
      percentage: 4.2,
    },
    {
      title: "KES",
      pic: kespic,
      description: "Kenyan Shillings",
      symbol: "KSh",
      amount: "KES 1700/USD",
      status: "decrease",
      percentage: 4.2,
    },
  ];
  return (
    <div className="font-sans max-w-md">
      {/* <p className="text-white p-3">Complete Your Registration</p>

      <div>
        {" "}
        <Link to="/kyc">
        <div className="flex justify-between mx-3 my-4 items-center">
          <div className="bg-atlas_gold rounded-full w-7 h-7 flex justify-center items-center text-black ">
            <p>1</p>
          </div> */}

      {/* Connector Line */}
      {/* <div
            style={{
              height: "2px",
              backgroundColor: "#D5B071", // Or any color you prefer
              flexGrow: 1, // Allows the line to fill the space between
            }}
          ></div>

          <div className="w-3/4 h-3/4 bg-atlas_gray border border-solid border-atlas_gold rounded-lg p-2">
            <p className="text-sm text-white">Verify your Account</p>
            <div className="flex pt-3">
              <p className="text-xs text-white/50">
                Complete your verification process to start trading
              </p>
            </div>
            <div className="flex justify-end">
              <img src={boxicon} alt="" className="w-14 h-14" />
            </div>
          </div>
        </div>
        </Link>

        <div className="flex justify-between mx-3 my-4 items-center">
          <div className="bg-atlas_grey rounded-full w-7 h-7 flex justify-center items-center text-black ">
            <p>2</p>
          </div> */}

      {/* Connector Line */}
      {/* <div
            style={{
              height: "2px",
              backgroundColor: "#2E2E2E", // Or any color you prefer
              flexGrow: 1, // Allows the line to fill the space between
            }}
          ></div>

          <div className="w-3/4 h-3/4 bg-atlas_gray border border-solid border-white/30 rounded-lg p-2">
            <p className="text-sm text-white">Make A Deposit</p>
          </div>
        </div>
        <div className="flex justify-between mx-3 my-4 items-center">
          <div className="bg-atlas_grey rounded-full w-7 h-7 flex justify-center items-center text-black ">
            <p>3</p>
          </div> */}

      {/* Connector Line */}
      {/* <div
            style={{
              height: "2px",
              backgroundColor: "#2E2E2E", // Or any color you prefer
              flexGrow: 1, // Allows the line to fill the space between
            }}
          ></div>

          <div className="w-3/4 h-3/4 bg-atlas_gray border border-solid border-white/30 rounded-lg p-2">
            <p className="text-sm text-white">Start Trading</p>
          </div>
        </div>
      </div> */}
      <section className="flex flex-col space-y-5 font-manrope ml-5 mt-5 text-white">
        <div className="flex flex-col space-y-5 p-4 rounded-lg border border-atlas_orange bg-atlas_gray">
          <h4 className="font-semibold text-xl">Complete Your KYC</h4>
          <p className="text- font-light">You have limited access. complete KYC to get full access.</p>
        </div>
        <Link to="/kyc">
          <button className="btn">
            Complete your KYC
          </button>
        </Link>
      </section>

      <div className="mb-24 ml-5 mt-16">
        <div className="flex items-center justify-between font-manrope">
          <p className="text-white text-lg font-semibold">Supported Currencies</p>
          <CurrencyDropdown />
        </div>

        {data.map((item, index) => (
          <div className="flex justify-between py-4 font-manrope" key={index}>
            <div className="flex">
              <img src={item.pic} alt="" className="w-10 h-10 rounded-full" />
              <div className="flex flex-col pl-3 text-white font-semibold text-sm ">
                {item.title} ({item.symbol})
                <div className="text-white font-normal text-xs">
                  {item.description}
                </div>
              </div>
            </div>

            <div>
              <div className="flex flex-col text-white font-semibold text-sm ">
                <p className="text-center font-thin">{item.amount}</p>
                <div
                  className={`py-1 flex self-end w-fit pl-1 pr-2 rounded-md item-center text-white font-semibold text-sm ${item.status === "increase"
                    ? "bg-atlas_green/20"
                    : "bg-red-500/20"
                    }`}
                >
                  <div className="flex items-center">
                    {item.status === "increase" ? (
                      <MdArrowUpward className="text-atlas_green" />
                    ) : (
                      <MdArrowDownward className="text-red-500" />
                    )}
                  </div>
                  <p
                    className={`${item.status === "increase"
                      ? "text-atlas_green"
                      : "text-red-500"
                      } font-normal text-xs`}
                  >
                    {item.percentage}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="flex items-center font-manrope">
          <a className="text-atlas_orange underline cursor-pointer hover:brightness-90">See all</a>
        </div>
        <section className="flex flex-col space-y-5 font-manrope mt-5 text-white">
          <div className="flex flex-col space-y-5 p-5 rounded-lg bg-balance-pattern bg-cover bg-no-repeat bg-neutral-600 bg-blend-overlay">
            <h4 className="font-semibold text-2xl">Trade in High Volumes</h4>
            <p className="text- font-light">Buy or sell assets above $50,000 with personalized service and the best rates.</p>
            <button className="self-start bg-neutral-800 bg-opacity-15 border border-neutral-200 hover:bg-opacity-75 cursor-pointer rounded-lg px-3 py-1.5">Trade now</button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RightSidebar;
