import { useState, useEffect, SetStateAction } from "react";
import Header from "../Components/LandingPage/Header";
import { Link } from "react-router-dom";
import Card from "../Components/LandingPage/Card";
import LandingPageImg from "../assets/Globe.png";
import Icon from "../assets/Icons.png";
import Icon2 from "../assets/Icons.svg";
import Section1_2Img from "../assets/section1.png";
import Section1_4Img from "../assets/section1.1.png";
import Section1_3Img from "../assets/section1.2.png";
import Section1_1Img from "../assets/section1.3.png";
import aboutus1 from "../assets/aboutus1.png";
import aboutus2 from "../assets/aboutus2.png";
import aboutus3 from "../assets/aboutus3.png";
import aboutus4 from "../assets/aboutus4.svg";
import aboutus5 from "../assets/aboutus5.svg";
import aboutus6 from "../assets/aboutus6.svg";
import Transaction1 from "../assets/Transaction1.png";
import Transaction2 from "../assets/Transaction2.png";
import Transaction3 from "../assets/Transaction3.png";
import Transaction4 from "../assets/Transaction4.png";
import Operator from "../assets/operator-bg.png";
import Faq from "../Components/LandingPage/Faq";
import Footer from "../Components/LandingPage/Footer";
import ResponsiveCarousel from "../Components/LandingPage/ResponsiveCarousel";

const Homapage2 = () => {
  const [selected, setSelected] = useState<"individual" | "business">(
    "individual"
  );
  const textContent = {
    individual: (
      <>
        Explore our banking solutions that empower you <br /> to manage your
        finances effortlessly and securely.
      </>
    ),
    business: (
      <>
        Explore our banking solutions that empower your <br /> business to
        manage your finances effortlessly and securely.
      </>
    ),
  };
  const imagesArray = [Transaction1, Transaction2, Transaction3, Transaction4];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  // Map texts to their corresponding image indices
  const textImageMap = {
    topLeft: 0,
    bottomLeft: 1,
    topRight: 2,
    bottomRight: 3,
  };

  // Automatic image change effect
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!isHovering) {
      interval = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % imagesArray.length
        );
      }, 5000); // Change image every 5 seconds
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isHovering, imagesArray.length]);

  const handleMouseEnter = (index: SetStateAction<number>) => {
    setIsHovering(true);
    setCurrentImageIndex(index);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const emailAddress = "hi@atlaxchange.com";
  const phoneNumber = "+2348022841012";
  const emailSubject = "Inquiry";
  const emailBody = "Hello, I would like to ask about...";

  return (
    <div className="max-w-screen-2xl mx-auto">
      <Header />

      <div className="flex lg:flex-row flex-col my-10 lg:mt-24 lg:mb-44 mx-8 lg:ml-28 lg:mr-28">
        <div className="lg:w-7/12 w-full flex-auto lg:mt-24">
          <p className="text-white text-4.5xl font-bold font-manrope pb-5 lg:px-5 leading-tight tracking-tight		">
            <span className="text-atlas_black">Redefine Your Financial</span>{" "}
            <span className="text-atlas_black">Experience with Atlax</span>{" "}
            <span className="text-atlas_black">Exchange.</span>
          </p>
          <p className="text-atlas_black text-base font-medium font-manrope pb-5 lg:px-5 pr-5">
            Your TRUSTED platform for secure treasury Management, International
            transfers, efficient payments, flexible lending, and smart
            investments.
          </p>
          <Link to="https://forms.gle/KgNJbxgrJ9KRJKsG9">
            <button className="py-3 px-6 lg:py-2 lg:px-5 lg:mx-5 bg-atlas_orange text-white rounded-lg lg:text-base text-lg">
              Join our waitlist
            </button>
          </Link>
        </div>
        <div className="lg:w-8/12 lg:flex py-5 lg:py-0 w-full flex-auto">
          <img
            src={LandingPageImg}
            className="w-full max-w-screen-md mx-auto"
            alt="globe"
          />
        </div>
      </div>

      <div className="lg:mx-52 mx-8">
        <div className="bg-atlas_black rounded-full flex justify-evenly w-56 px-5 py-2">
          <img src={Icon} alt="Icon" />
          <p className="text-white">Why Atlax Exchange? </p>
        </div>

        <p className="font-manrope font-semibold text-xl py-7">
          We make financial services accessible, affordable,
          <br /> and secure.
        </p>
        <p className="font-manrope font-semibold text-xl">
          Discover a better way to manage your finances, <br /> designed with
          your needs in mind.
        </p>
      </div>

      <div className="lg:bg-MapBg bg-contain bg-no-repeat lg:flex items-center justify-center gap-24 w-full h-full my-28 mr-10">
        <div className="grid grid-rows-2 grid-flow-col lg:gap-4 gap-14 mt-40">
          <div className="flex justify-center items-center">
            <Card
              imageSrc={Section1_1Img}
              title="Fast Transactions"
              description="Get the speed and efficiency you need to power your financial operations. Our solutions are designed to make every transaction seamless."
            />
          </div>

          <div className="flex justify-center items-center lg:mb-0 mb-7">
            <Card
              imageSrc={Section1_2Img}
              title="Competitive Rates"
              description="Benefit from favorable OTC rates that help you maximize your trading potential and make every transaction count."
            />
          </div>
        </div>
        <div className="grid grid-rows-2 grid-flow-col lg:gap-4 gap-14">
          <div className="flex justify-center items-center lg:mt-0 mt-7">
            <Card
              imageSrc={Section1_3Img}
              title="Secure Platform"
              description="Your safety is our priority, with robust security measures in place to keep your assets and information protected."
            />
          </div>

          <div className="flex justify-center items-center ">
            <Card
              imageSrc={Section1_4Img}
              title="Expert Support"
              description="Get guidance and support whenever you need it, with a dedicated team ready to assist you at every step of your financial journey."
            />
          </div>
        </div>
      </div>

      <div className="bg-atlas_black lg:py-28 py-14 lg:px-0 px-20">
        <p className="text-center text-white font-manrope font-semibold text-3xl capitalize">
          at atlax Exchange, we're redefining digital finance
        </p>
      </div>

      <div className="py-16 mx-8" id="services">
        <div className="flex lg:flex-row flex-col justify-between lg:items-center lg:mr-10 lg:ml-20">
          <div className="bg-atlas_black rounded-full flex justify-evenly w-40 px-5 py-2">
            <img src={Icon} alt="Icon" />
            <p className="text-white">Our Services</p>
          </div>

          <div className="w-50 lg:w-40 flex justify-between bg-atlas_lightgrey lg:py-2 lg:px-2 py-2 px-2 my-5 lg:my-0 rounded-md font-manrope font-semibold">
            <button
              onClick={() => setSelected("individual")}
              className={`lg:px-2 lg:-py-0 px-4 py-2 rounded text-xs ${
                selected === "individual"
                  ? "bg-atlas_orange text-white"
                  : "bg-atlas_slategrey text-atlas_grey"
              }`}
            >
              Individual
            </button>
            <button
              onClick={() => setSelected("business")}
              className={`lg:px-2 lg:-py-0 px-4 py-2 rounded text-xs ${
                selected === "business"
                  ? "bg-atlas_orange text-white"
                  : "bg-atlas_slategrey text-atlas_grey"
              }`}
            >
              Business
            </button>
          </div>
        </div>

        <p className="lg:ml-20 font-semibold font-manrope text-2xl mt-5 mb-10 lg:my-14">
          {textContent[selected]}
        </p>

        <ResponsiveCarousel selected={selected} />
      </div>

      <div className="bg-atlas_silver pt-20" id="about-us">
        <div className="bg-atlas_black rounded-full flex justify-evenly w-40 px-5 py-2 lg:mx-50 mx-10">
          <img src={Icon} alt="Icon" />
          <p className="text-white">About Us</p>
        </div>

        <div className="lg:mx-36">
          <div className="flex lg:flex-row flex-col flex-wrap gap-4 my-10">
            <div className="flex-1 items-center ">
              <div className="pt-20 px-14">
                <div className="flex items-center gap-1 py-2">
                  <img src={aboutus4} alt="" />
                  <p className="text-xs font-manrope font-semibold">
                    Who we are.
                  </p>
                </div>

                <p className="text-xl font-manrope font-semibold">
                  We aim to provide you with accessible and <br /> affordable
                  financial services, all in one place.
                </p>

                <p className="text-base font-manrope font-normal my-5">
                  We offer a comprehensive suite of services, including foreign
                  exchange, remittance, payments, lending, and investment
                  opportunities. We believe in simplifying finance so everyone
                  can benefit from greater financial access and empowerment.
                </p>
              </div>
            </div>

            <div className="flex-1 px-14 lg:px-0">
              <img className="w-full" src={aboutus3} alt="" />
            </div>
          </div>

          <div className="flex flex-wrap gap-14 lg:flex-row-reverse flex-col my-10">
            <div className="flex-1 items-center">
              <div className="pt-20 lg:px-10 px-14">
                <div className="flex items-center gap-1 py-2">
                  <img src={aboutus5} alt="" />
                  <p className="text-xs font-manrope font-semibold">
                    Our Mission
                  </p>
                </div>

                <p className="text-xl font-manrope font-semibold">
                  To make financial services more accessible and <br />{" "}
                  affordable for everyone.
                </p>

                <p className="text-base font-manrope font-normal my-5 ">
                  We are committed to making financial services accessible and
                  affordable for everyone by leveraging technology, reducing
                  costs, and simplifying processes. Our solutions are designed
                  to empower individuals and businesses, ensuring seamless
                  access to the tools they need to thrive financially.
                </p>
              </div>
            </div>

            <div className="flex-1 lg:px-0 px-14">
              <img className="w-full" src={aboutus1} alt="" />
            </div>
          </div>

          <div className="flex flex-wrap lg:flex-row flex-col gap-4 my-10">
            <div className="flex-1 items-center">
              <div className="pt-20 px-14">
                <div className="flex items-center gap-1 py-2">
                  <img src={aboutus6} alt="" />
                  <p className="text-xs font-manrope font-semibold">
                    Our Vision
                  </p>
                </div>
                <p className="text-xl font-manrope font-semibold mb-2">
                  Revolutionizing Global Finance for a More <br /> Accessible
                  and Inclusive Future
                </p>
                <p className="text-base font-manrope font-normal my-5">
                  To be the leading digital platform that revolutionizes access
                  to global financial services, building a more inclusive
                  financial future for individuals and businesses worldwide.
                </p>
              </div>
            </div>

            <div className="flex-1 lg:px-0 px-14">
              <img className="w-full" src={aboutus2} alt="" />
            </div>
          </div>

          <div className="grid lg:grid-col-2 grid-rows-1 lg:grid-flow-col py-10">
            <div className="pt-20 px-14">
              <div className="flex items-center gap-1">
                <img src={aboutus6} alt="" />
                <p className="text-xs font-manrope font-semibold">
                  Our Commitment
                </p>
              </div>
              <p className="text-3xl font-manrope font-semibold">Security</p>
              <p className="text-base font-manrope font-normal my-5">
                Your security is paramount. We employ advanced encryption and
                secure protocols to protect your data and ensure safe
                transactions every time.
              </p>
            </div>
            <div className="pt-20 lg:px-10 px-14">
              <div className="flex items-center gap-1">
                <img src={aboutus6} alt="" />
                <p className="text-xs font-manrope font-semibold">
                  Our Commitment
                </p>
              </div>
              <p className="text-3xl font-manrope font-semibold">
                Customer Support
              </p>
              <p className="text-base font-manrope font-normal my-5">
                Our customer service team is here to assist you with any
                questions or concerns. From inquiries about our services to
                detailed support for your transactions, we’re available to
                provide the help you need.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-atlas_black lg:px-36 px-10 pt-20">
        <div className="bg-atlas_grey rounded-full flex justify-evenly w-40 px-5 py-2 ">
          <img src={Icon2} alt="Icon" />
          <p className="text-atlas_black">How it works</p>
        </div>
        <p className="text-atlas_grey font-manrope text-3xl pt-7 lg:pb-28 pb-7">
          Make transactions in 4 easy steps.
        </p>

        {/* Desktop View */}
        <div className="hidden lg:grid grid-cols-1 md:grid-cols-3 md:gap-4 items-center ">
          {/* First Column - Left Texts */}
          <div className="flex flex-col justify-between h-full text-atlas_grey">
            <div
              className="mt-10 mb-20 cursor-pointer"
              onMouseEnter={() => handleMouseEnter(textImageMap.topLeft)}
              onMouseLeave={handleMouseLeave}
            >
              <p className="bg-atlas_grey w-10 py-2 px-4 mt-3 text-atlas_black rounded-full">
                1
              </p>
              <p className="py-3 font-semibold text-xl">
                Register and Verify your Account
              </p>

              <p className="font-light text-base">
                Get started by creating an account on our secure platform. Our
                verification process is designed to be smooth, ensuring a safe
                experience for all users.
              </p>
            </div>

            <div
              className="mt-10 mb-20 cursor-pointer"
              onMouseEnter={() => handleMouseEnter(textImageMap.bottomLeft)}
              onMouseLeave={handleMouseLeave}
            >
              <p className="bg-atlas_grey w-10 py-2 px-4 mt-3 text-atlas_black rounded-full">
                3
              </p>
              <p className="py-3 font-semibold text-xl">Select Services</p>

              <p className="font-light text-base">
                Whether it’s foreign exchange, remittance, lending, or payment
                solutions, simply choose the service that meets your needs.
              </p>
            </div>
          </div>

          {/* Second Column - Center Image (hidden on mobile) */}
          <div className="hidden md:flex items-center justify-center">
            <img
              src={imagesArray[currentImageIndex]}
              alt="Center_Image"
              className="w-44 h-44 object-cover rounded-full"
            />
          </div>

          {/* Third Column - Right Texts */}
          <div className="flex flex-col justify-between h-full text-atlas_grey">
            <div
              className="mt-10 mb-20 cursor-pointer"
              onMouseEnter={() => handleMouseEnter(textImageMap.topRight)}
              onMouseLeave={handleMouseLeave}
            >
              <p className="bg-atlas_grey w-10 py-2 px-4 mt-3 text-atlas_black rounded-full">
                2
              </p>
              <p className="py-3 font-semibold text-xl">Access the Platform</p>

              <p className="font-light text-base">
                Navigate our intuitive interface with ease. Our platform is
                designed for user-friendliness, so you can quickly find the
                services you need.
              </p>
            </div>

            <div
              className="mt-10 mb-20 cursor-pointer"
              onMouseEnter={() => handleMouseEnter(textImageMap.bottomRight)}
              onMouseLeave={handleMouseLeave}
            >
              <p className="bg-atlas_grey w-10 py-2 px-4 mt-3 text-atlas_black rounded-full">
                4
              </p>
              <p className="py-3 font-semibold text-xl">
                Complete Transactions
              </p>

              <p className="font-light text-base">
                Experience secure, streamlined transactions with the assistance
                of our dedicated support team, who are here to ensure everything
                goes smoothly.
              </p>
            </div>
          </div>
        </div>

        {/* Mobile View */}
        <div className="md:hidden flex flex-col items-center text-atlas_grey">
          <div className="mt-10 mb-10">
            <p className="bg-atlas_grey w-10 py-2 px-4 mt-3 text-atlas_black rounded-full">
              1
            </p>
            <p className="py-3 font-semibold text-xl">
              Register and Verify your Account
            </p>

            <p className="font-light text-base">
              Get started by creating an account on our secure platform. Our
              verification process is designed to be smooth, ensuring a safe
              experience for all users.
            </p>
          </div>

          <div className="mt-10 mb-10">
            <p className="bg-atlas_grey w-10 py-2 px-4 mt-3 text-atlas_black rounded-full">
              2
            </p>
            <p className="py-3 font-semibold text-xl">Access the Platform</p>

            <p className="font-light text-base">
              Navigate our intuitive interface with ease. Our platform is
              designed for user-friendliness, so you can quickly find the
              services you need.
            </p>
          </div>

          <div className="mt-10 mb-10">
            <p className="bg-atlas_grey w-10 py-2 px-4 mt-3 text-atlas_black rounded-full">
              3
            </p>
            <p className="py-3 font-semibold text-xl">Select Services</p>

            <p className="font-light text-base">
              Whether it’s foreign exchange, remittance, lending, or payment
              solutions, simply choose the service that meets your needs.
            </p>
          </div>
          <div className="mt-10 mb-10">
            <p className="bg-atlas_grey w-10 py-2 px-4 mt-3 text-atlas_black rounded-full">
              4
            </p>
            <p className="py-3 font-semibold text-xl">Complete Transactions</p>

            <p className="font-light text-base">
              Experience secure, streamlined transactions with the assistance of
              our dedicated support team, who are here to ensure everything goes
              smoothly.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-atlas_silver lg:flex justify-between">
        <div className="lg:ml-32 mx-10 lg:py-14 pt-7">
          <div className="bg-atlas_black rounded-full flex justify-evenly w-40 px-5 py-2 my-7">
            <img src={Icon} className="object-contain" alt="Icon" />
            <p className="text-white">Get in touch</p>
          </div>

          <div className="flex justify-between">
            <div>
              <h3 className="font-manrope text-3xl font-semibold">
                Have questions or need assistance?
              </h3>

              <p className="font-manrope text-lg font-normal py-5">
                Contact us for support or more information about our <br />{" "}
                services.
              </p>

              <div className="hidden lg:block">
                <a
                  href={`mailto:${emailAddress}?subject=${encodeURIComponent(
                    emailSubject
                  )}&body=${encodeURIComponent(emailBody)}`}
                >
                  <button className="py-2 px-4 bg-atlas_orange text-white rounded-md text-xs font-manrope">
                    Send an Email
                  </button>
                </a>
                <a href={`tel:${phoneNumber}`}>
                  <button className="py-2 px-4 mx-5 bg-atlas_orange text-white rounded-md text-xs font-manrope">
                    Place a call
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end" id="contact-us">
          <img className="h-full object-contain" src={Operator} alt="Icon" />
        </div>
        <div className="lg:hidden flex justify-center py-5 ">
          <a
            href={`mailto:${emailAddress}?subject=${encodeURIComponent(
              emailSubject
            )}&body=${encodeURIComponent(emailBody)}`}
          >
            <button className="py-2 px-4 bg-atlas_orange text-white rounded-md text-base font-manrope">
              Send an Email
            </button>
          </a>
          <a href={`tel:${phoneNumber}`}>
            <button className="py-2 px-4 mx-5 bg-atlas_orange text-white rounded-md text-base font-manrope">
              Place a call
            </button>
          </a>
        </div>
      </div>

      <div className="bg-atlas_offwhite py-20" id="faq">
        <div className="bg-atlas_black rounded-full flex justify-evenly w-32 px-5 py-2 lg:mb-20 lg:mx-34 mx-10 mb-7">
          <img src={Icon} alt="Icon" />
          <p className="text-white">FAQs</p>
        </div>
        <Faq />
      </div>

      <Footer />
    </div>
  );
};

export default Homapage2;
