import React, { useState } from "react"; 
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";


function BalanceCard() {
  const currency = [
    {
      name: "NGN",
      symbol: "₦",
      amount: "1,230,000.00",
    },
    {
      name: "USD",
      symbol: "$",
      amount: "12,300.00",
    },
    {
      name: "GBP",
      symbol: "£",
      amount: "1,230.00",
    },
  ];

  const [viewBalance, setViewBalance] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationDirection, setAnimationDirection] = useState(" ");

  const prevContact = () => {
    setAnimationDirection("slide-in-right");
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? currency.length - 1 : prevIndex - 1
      );
    }, 200);
  };

  const nextContact = () => {
    setAnimationDirection("slide-in-left");
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === currency.length - 1 ? 0 : prevIndex + 1
      );
    }, 200);
  };

  const toggleViewBalance = () => {
    setViewBalance(!viewBalance);
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className={`${animationDirection} relative font-manrope max-h-40 lg:max-h-48 px-8 py-4 pb-16 bg-balance-pattern bg-cover bg-no-repeat bg-neutral-600 bg-blend-overlay overflow-hidden rounded-lg flex justify-between flex-col`}>
        <div className="flex items-start justify-between text-white">
          <p className="pb-2 text-xl font-sans">Balance({currency[currentIndex].name}) </p>
          <button
            className=""
            onClick={toggleViewBalance}>
            {viewBalance ?
              (<FiEyeOff className="size-7 cursor-pointer hover:bg-opacity-30 hover:bg-neutral-600 p-1 rounded-full" />) :
              (<FiEye className="size-7 cursor-pointer hover:bg-opacity-30 hover:bg-neutral-600 p-1 rounded-full" />)}
          </button>
        </div>
        <section>
          {viewBalance ? (<p className="text-white font-manrope font-semibold text-3xl">********</p>) :
            (<p className="text-white font-manrope font-bold text-3xl lg:text-4xl">
              {currency[currentIndex].symbol}
              {currency[currentIndex].amount}
            </p>)}
        </section>

        {/* Navigation Arrows */}
        <button
          onClick={prevContact}
          className="absolute top-1/2 left-1 transform bg-transparent text-white -translate-y-1/2"
        >
          <IoIosArrowBack className="size-6 cursor-pointer hover:bg-opacity-30 hover:bg-neutral-600 p-1 rounded-full" />
        </button>
        <button
          onClick={nextContact}
          className="absolute top-1/2 right-1 transform  bg-transparent text-white -translate-y-1/2"
        >
          <IoIosArrowForward className="size-6 cursor-pointer hover:bg-opacity-30 hover:bg-neutral-600 p-1 rounded-full" />
        </button>
      </div>
      {/* <div className="flex items-center justify-between space-x-2 px-4">
        <span className="p-[2px] rounded-full w-1/3 brightness-50 bg-atlas_orange"></span>
        <span className="p-[2px] rounded-full w-1/3 bg-atlas_orange"></span>
        <span className="p-[2px] rounded-full w-1/3 brightness-50 bg-atlas_orange"></span>
      </div> */}
      <div className="flex items-center justify-between space-x-5 px-4">
        {currency.map((_, index) => (
          <div
            key={index}
            className={`p-[2px] rounded-full w-1/3 bg-atlas_orange ${index === currentIndex ? "brightness-100" : "brightness-50"
              }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default BalanceCard;