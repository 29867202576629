
import React, { useState } from "react";
import LoginImage from "../../assets/LoginImage.svg";
import logo from "../../assets/Logo.svg";
import EmailComponent from '../../Components/ForgotPassword/Email'
import OtpComponent from '../../Components/ForgotPassword/Otp'
import ResetComponent from '../../Components/ForgotPassword/Reset'
import { Link } from "react-router-dom";
import SideImage from "../../Components/SideImage";
import Logo from "../../Components/Logo";

type ComponentKey = 'EmailComponent' | 'OtpComponent' | 'ResetComponent';
const componentMap = {
  EmailComponent,
  OtpComponent,
  ResetComponent,
};



const ForgotPasswordPage = () => {
  const [activeComponent, setActiveComponent] = useState<ComponentKey>('EmailComponent');
  const [email, setEmail] = useState<string>('');

  const componentSequence: any = ['EmailComponent', 'OtpComponent', 'ResetComponent'];

  const goToNextComponent = () => {
    const currentIndex = componentSequence.indexOf(activeComponent);
    const nextIndex = (currentIndex + 1) % componentSequence.length;
    setActiveComponent(componentSequence[nextIndex]);
  };

  const goToPreviousComponent = () => {
    const currentIndex = componentSequence.indexOf(activeComponent);
    const prevIndex = (currentIndex - 1 + componentSequence.length) % componentSequence.length;
    setActiveComponent(componentSequence[prevIndex]);
  };

  const ActiveComponent = componentMap[activeComponent]; // Dynamically determine the component

  return (
    <div>
      <div className="lg:flex ">
        <div className="bg-white dark:bg-atlas_black flex flex-col flex-1 min-h-screen lg:h-auto ">
          <div className="lg:mx-16 md:mx-16 mx-12 pt-5 flex justify-between">
            <Logo />
            <Link to="/signup" className="lg:hidden text-white">
              Sign up
            </Link>
          </div>

          <div className="flex flex-col">
            <ActiveComponent
              email={email}
              setEmail={setEmail} // Pass setter function as a prop
              onNext={goToNextComponent}
              onPrev={goToPreviousComponent}
            />
          </div>
        </div>

        <div className="hidden lg:block font-sans bg-login-pattern bg-atlas_dark bg-cover flex-1">
          <div className="relative flex items-center h-full">
            <SideImage />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;