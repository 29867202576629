import logo from "../assets/Logo.svg";
import { Link } from "react-router-dom";


function Logo() {
  return (
    <>
      <Link to="/" className="flex items-center justify-between">
        <img className="w-12 h-12 p-2 cursor-pointer fill-atlas_orange" src={logo} alt="logo" />
        <h4 className="text-atlas_orange font-bold cursor-pointer">Atlax Exchange™</h4>
      </Link>
    </>
  )
}

export default Logo;