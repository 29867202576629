import { FaChevronDown } from "react-icons/fa6";

function CurrencyDropdown() {
  return (
    <div>
      <div className="grid grid-cols-1 hover:brightness-95">
        <select
          id="currency"
          name="currency"
          defaultValue="USD"
          className="col-start-1 row-start-1 w-full cursor-pointer appearance-none border-2 border-neutral-500 rounded-md py-1.5 pl-3 pr-8 text-gray-100 text-base bg-neutral-800 outline-none sm:text-sm/6"
        >
          <option>USD</option>
          <option>GBP</option>
          <option>NGN</option>
          <option>KES</option>
        </select>
        <FaChevronDown
          aria-hidden="true"
          className="pointer-events-none col-start-1 row-start-1 mr-2 size-4 self-center justify-self-end text-gray-100 sm:size-4"
        />
      </div>
    </div>
  )
}

export default CurrencyDropdown;