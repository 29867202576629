// TableComponent.jsx
import React from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { TbDownload } from "react-icons/tb";
import { MdOutlineShare } from "react-icons/md";
import AllWallets from "./WalletsDropdown";


interface Column {
  field: string;
  headerName: string;
  renderCell?: (row: any) => JSX.Element;
}

interface crypto {
  imageSrc: any;
  title: string;
}

interface amount {
  currency: string;
}

interface change {
  percentage: number;
  status: string;
}

interface DataRow {
  id: number;
  crypto: crypto[]; // Now supports a variable number of items
  amount: amount[];
  price: number;
  change: change[];
  action: () => void;
}

interface ResponsiveTableProps {
  columns: Column[];
  data?: DataRow[];
}

const TableComponent: React.FC<ResponsiveTableProps> = ({ columns, data }) => {
  return (
    <div className="overflow-x-auto font-manrope">
      <section className="flex items-center p-5 text-gray-100 rounded-t-xl justify-between bg-neutral-800 border-2 border-neutral-700">
        <div>
          <h2 className="text-lg cursor-default select-none">Recent Transactions</h2>
        </div>
        <div className="flex items-center justify-between space-x-7">
          <button title="Download" className="cursor-pointer ">
            <TbDownload className="size-5 text-neutral-400 hover:text-neutral-200" />
          </button>
          <button title="Share" className="cursor-pointer">
            <MdOutlineShare className="size-5 text-neutral-400 hover:text-neutral-200" />
          </button>
          <AllWallets />
        </div>
      </section>
      <table className="min-w-full font-sans table-auto border-2 border-neutral-700 rounded-b-xl border-separate">
        <thead className="border-b">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className="text-lg font-normal text-white/50 px-6 py-4 text-left"
              >
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex} className="">
              {row.amount.map((item, itemIndex) => (
                <td
                  key={itemIndex}
                  className="text-sm font-light px-6 py-4 whitespace-nowrap"
                >
                  <div className="flex">
                    <div className="font-manrope text-white font-light text-base">{item.currency}</div>
                  </div>
                </td>
              ))}
              {row.crypto.map((item, itemIndex) => (
                <td
                  key={itemIndex}
                  className="text-sm font-light px-6 py-4 whitespace-nowrap"
                >
                  <div className="flex items-center">
                    <img
                      src={item.imageSrc}
                      alt=""
                      className="w-10 h-10 rounded-full"
                    />
                    <div className="flex pl-3 text-white text-base font-manrope font-normal">
                      {item.title}
                    </div>
                  </div>
                </td>
              ))}
              <td
                className="text-sm font-light text-white px-6 py-4 whitespace-nowrap"
              >
                ${row.price}
              </td>
              {row.change.map((item, itemIndex) => (
                <td
                  key={itemIndex}
                  className="text-sm font-light px-6 py-4 whitespace-nowrap"
                >
                  <div className={`w-3/4 py-1 flex justify-center rounded-md item-center text-white font-semibold text-sm ${item.status === 'increase' ? 'bg-atlas_green/20' : 'bg-red-500/20'}`}>
                    <div className="flex items-center">
                      {item.status === 'increase' ? <MdArrowUpward className="text-atlas_green" /> : <MdArrowDownward className="text-red-500" />}
                    </div>
                    <p className={`${item.status === 'increase' ? 'text-atlas_green' : 'text-red-500'} font-normal text-xs`}>{item.percentage}%</p>
                  </div>
                </td>
              ))}
              <td className=" px-6 py-4">
                <button className="text-white text-xs " onClick={row.action}>
                  Manage
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-end py-3 text-gray-200">
        <a className="underline text-sm cursor-pointer hover:brightness-90">
          See all transactions
        </a>
      </div>
    </div>
  );
};

export default TableComponent;
