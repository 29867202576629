import React from 'react'
import { useDashboardContext } from '../../Context/DashboardContext';


const SettingsPage = () => {
  const { user } = useDashboardContext();

  return (
    <>

    </>
  )
}

export default SettingsPage