import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { FiSun } from "react-icons/fi";
import { FiMoon } from "react-icons/fi";


function ThemeToggle() {
  const [enabled, setEnabled] = useState(false);
  // const [darkMode, setDarkMode] = useState(false);

  // const toggleDarkMode = () => {
  //   setDarkMode(!darkMode)
  // }

  return (
    <>
      <Switch
        checked={enabled}
        onChange={setEnabled}
        // onClick={toggleDarkMode}
        className="group relative inline-flex h-8 w-[126px] shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-neutral-700 transition-colors duration-200 ease-in-out outline-none"
      >
        <span className="sr-only">Use setting</span>
        <span className="pointer-events-none relative inline-block w-fit size-full transform rounded-full bg-neutral-400 shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-14">
          <span
            aria-hidden="true"
            className="absolute inset-0 flex w-fit size-full px-2 space-x-1 items-center justify-center bg-neutral-500 rounded-full transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
          >
            <FiSun className='size-4' />
            <label className='text-sm font-semibold'>Light</label>
          </span>
          <span
            aria-hidden="true"
            className="absolute inset-0 flex w-fit size-full px-2 space-x-1 items-center justify-center bg-neutral-500 rounded-full opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
          >
            <FiMoon className='size-4' />
            <label className='text-sm font-semibold'>Dark</label>
          </span>
        </span>
      </Switch>

      {/* <label htmlFor="Toggle4" className="inline-flex items-center mt-2 p-1 cursor-pointer rounded-full bg-neutral-700 text-gray-100">
        <input id="Toggle4" type="checkbox" className="hidden peer" />
        <span className="flex items-center rounded-full space-x-1 px-2 py-1 bg-neutral-500 peer-checked:bg-transparent">
          <FiSun className='size-4' />
          <label className='text-sm font-semibold'>Light</label>
        </span>
        <span className="flex items-center rounded-full space-x-1 px-2 py-1 bg-transparent peer-checked:bg-neutral-800">
          <FiMoon className='size-4' />
          <label className='text-sm font-semibold'>Dark</label>
        </span>
      </label> */}
    </>
  )
}

export default ThemeToggle;