import logo from "../assets/white_logo.svg";
import { Link } from "react-router-dom";


function HomeLogo() {
  return (
    <>
      <Link to="/dashboard/">
        <img className="" src={logo} alt="logo" />
      </Link>
    </>
  )
}

export default HomeLogo;