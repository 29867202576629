import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import placeholder from "../../assets/placeholder.svg";
import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/arrow-right.svg";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import TableComponent from "../TableComponent";
import Ellipse from "../../assets/Ellipse.svg";
import gtbpic from "../../assets/gtbank.svg";
import moniepic from "../../assets/moniepoint.jpeg";
import sterling from "../../assets/sterlingicon.jpeg";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import Modal from "../Modals/Modal";
import AllTransactions from "./AllTransactions";
import RightSidebar from "./RightSidebar";
import BulkMenu from "../BulkMenu";
import BalanceCard from "../BalanceCard";

// Register necessary components and plugins

const DashboardPage = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [isChecked, setIsChecked] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [currentView, setCurrentView] = useState("default");
  const [viewBalance, setViewBalance] = useState(false);


  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleTitle = (title: string) => {
    setContentType(title);
  };

  const toggleViewBalance = () => {
    setViewBalance(!viewBalance);
  };


  const columns = [
    { field: "date", headerName: "Date" },
    { field: "bank", headerName: "Bank" },
    { field: "beneficiary name", headerName: "Beneficiary name" },
    { field: "transaction id", headerName: "Transaction ID" },
    { field: "amount", headerName: "Amount" },
    // Add more columns as needed
  ];

  const dataTable = [
    {
      id: 1,
      crypto: [
        {
          imageSrc: gtbpic,
          title: "GTB",
        },
      ],
      amount: [{ currency: "11 Jul, 4:03am" }],
      price: 1.0,
      change: [{ percentage: 4.0, status: "increase" }],
      action: () => console.log("Action for row 1"),
    },
    {
      id: 2,
      crypto: [
        {
          imageSrc: gtbpic,
          title: "GTB",
        },
      ],
      amount: [{ currency: "11 Jul, 4:03am" }],
      price: 1.0,
      change: [{ percentage: 4.0, status: "decrease" }],
      action: () => console.log("Action for row 1"),
    },
    {
      id: 3,
      crypto: [
        {
          imageSrc: moniepic,
          title: "Moniepoint",
        },
      ],
      amount: [{ currency: "11 Jul, 4:03am" }],
      price: 1.0,
      change: [{ percentage: 4.0, status: "increase" }],
      action: () => console.log("Action for row 1"),
    },
    {
      id: 4,
      crypto: [
        {
          imageSrc: sterling,
          title: "Sterling Bank",
        },
      ],
      amount: [{ currency: "11 Jul, 4:03am" }],
      price: 1.0,
      change: [{ percentage: 4.0, status: "increase" }],
      action: () => console.log("Action for row 1"),
    },
    {
      id: 5,
      crypto: [
        {
          imageSrc: gtbpic,
          title: "GTB",
        },
      ],
      amount: [{ currency: "11 Jul, 4:03am" }],
      price: 1.0,
      change: [{ percentage: 4.0, status: "increase" }],
      action: () => console.log("Action for row 1"),
    },
    // Add more rows as needed
  ];

  const data = {
    labels: ["NGN", "USD", "KES", "Others"],
    datasets: [
      {
        data: [200, 70, 80, 40],
        backgroundColor: [
          "rgb(213,176,113)",
          "rgb(235,87,87)",
          "rgb(189,189,189)",
          "rgb(47,128,237)",
        ],
        borderColor: "transparent",
        hoverOffset: 4,
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    cutout: "70%",
    plugins: {
      legend: {
        display: true,
        position: "left",
        onClick: () => { },
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 6,
          font: {
            size: 12,
            weight: "normal",
          },
          color: "white",
          pointStyle: "rectRounded",
        },
      },
    },
  };

  const handleSeeAllClick = () => {
    setCurrentView("allTransactions");
  };

  return (
    <div className="flex">
      <div className="md:mx-6 mx-0 lg:w-4/5 w-full">
        {currentView === "default" && (
          <>
            {/* <p className="text-white py-4 font-sans font-extralight text-sm">
              Home {">"}{" "}
            </p> */}

            <div className="flex flex-col md:flex-row md:justify-between gap-3 mt-3 md:mt-6">
              <div className="w-1/2">
                <BalanceCard />

                <div className="flex items-center justify-between text-nowrap mt-4 mb-16">
                  <button
                    onClick={() => {
                      handleTitle("Trade");
                      handleOpenModal();
                    }}
                    className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
                  >
                    Add Money
                  </button>
                  <button
                    onClick={() => {
                      handleTitle("Buy");
                      handleOpenModal();
                    }}
                    className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
                  >
                    Send
                  </button>
                  <button
                    onClick={() => {
                      handleTitle("Convert");
                      handleOpenModal();
                    }}
                    className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
                  >
                    Convert
                  </button>
                  <button
                    className="text-white py-[2px] bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
                  >
                    <BulkMenu />
                  </button>
                </div>
              </div>
              {/*top part ends here*/}

              <div className="hidden md:block w-3/6 h-fit flex-auto px-6 py-4 bg-atlas_gray rounded-lg border-2 border-solid border-white/10">
                <h1 className="text-white text-xl font-sans">Asset distribution</h1>
                <div className="">
                  <div className="size-28 lg:size-40">
                    <Doughnut data={data} options={options} />
                  </div>
                </div>
              </div>
            </div>

            <TableComponent columns={columns} data={dataTable} />

            {/* <div className="my-10 underline pb-2 text-white flex justify-center items-center">
              <button
                className="text-sm font-sans text-white"
                onClick={handleSeeAllClick}
              >
                See All
              </button>
            </div> */}

            {/* <div className="my-5">
              <div className="flex justify-between my-4 text-white">
                <p>Recent Transaction</p>
              </div>
              <TableComponent columns={columns} />
            </div> */}

            <Modal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              type={contentType}
            />
          </>
        )}
        {currentView === "allTransactions" && <AllTransactions />}
      </div>

      <div className="hidden lg:flex border-l border-atlas_gray flex-grow">
        <RightSidebar />
      </div>
    </div>
  );
};

export default DashboardPage;
