import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { IoIosAddCircleOutline } from "react-icons/io";


function BulkMenu() {
  return (
    <Menu as="div" className="relative">
      <div>
        <MenuButton className="text-white px-3 pt-1">
          <IoIosAddCircleOutline className="size-6 font-thin" />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute left-0 mt-3 z-10 w-fit origin-top-left rounded-md bg-neutral-700 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1 text-atlas_grey">
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-nowrap text-sm data-[focus]:bg-neutral-600 outline-none"
            >
              Bulk Buy
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-nowrap text-sm data-[focus]:bg-neutral-600 outline-none"
            >
              Bulk Sell
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  )
}

export default BulkMenu;