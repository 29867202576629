// change this file to typescript
const dashboard = ({ className, fillColor, strokeColor }: { className: string, fillColor: string, strokeColor: string }) => {
  return (
    <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4532_13427)">
        <path d="M1.66797 8.90502L8.96014 3.07127C9.5688 2.58432 10.4338 2.58432 11.0425 3.07127L18.3346 8.90502" stroke="#1E1E1E" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.89672 3.94583L4.56339 6.65416C3.95818 7.14589 3.65557 7.39176 3.4938 7.73163C3.33203 8.07151 3.33203 8.46141 3.33203 9.24122V12.238C3.33203 15.3806 3.33203 16.9521 4.30834 17.9283C5.28466 18.9046 6.856 18.9046 9.9987 18.9046C13.1414 18.9046 14.7128 18.9046 15.689 17.9283C16.6654 16.9521 16.6654 15.3806 16.6654 12.238V9.24122C16.6654 8.46141 16.6654 8.07151 16.5036 7.73163C16.3418 7.39176 16.0392 7.14589 15.434 6.65416L12.1007 3.94583C11.0939 3.12777 10.5904 2.71875 9.9987 2.71875C9.40695 2.71875 8.90353 3.12777 7.89672 3.94583Z" stroke="#1E1E1E" />
        <path d="M8.125 14.1133H11.875" stroke={strokeColor} stroke-linecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_4532_13427">
          <rect width="20" height="20" fill={fillColor} transform="translate(0 0.571289)" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default dashboard;
