import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible, } from "react-icons/ai";
import { FiAlertCircle, } from "react-icons/fi";

import logo from "../../assets/Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "../../Components/spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import SideImage from "../../Components/SideImage";
import Logo from "../../Components/Logo";

const SignupPageBusiness = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email().required("email is required required"),
    number: Yup.string().email().required("phone number is required required"),
    password: Yup.string().required("password is required required"),
    confirm_password: Yup.string().required("confirm password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    business_name: Yup.string().required("Business name is required"),

  });

  const { getFieldProps, handleSubmit, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      number: "",
      password: "",
      confirm_password: "",
      business_name: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values: any) => {
      console.log(values);
      setLoading(true);
      // use the sendRequest function from appContext
      axios.post(`${process.env.REACT_APP_BASE_URL}/signup`, values)
        .then((response) => {
          if (response.data.message === "signup successful") {
            // const message = "Singup successful, we will contact you when your dashboard is ready";
            const message = "Singup successful, please proceed to complete your KYC";
            toast.info(message, {
              position: "top-center",
              theme: "dark",
              progressClassName: "!bg-atlas_gold",
            });
            localStorage.setItem("user", JSON.stringify(response.data.data));
            navigate("/kyc");
          }
        })
        .catch((error) => {
          console.log(error);
          const errMsg = error.response.data.errors;
          toast.error(errMsg ? errMsg : "An error occured, please try again", {
            position: "top-center",
            theme: "dark",
            progressClassName: "!bg-atlas_gold",
          });
        }).finally(() => { setLoading(false); });
      // Proceed with updating the password or other actions
    }
  });

  return (
    <div>
      <div className="lg:flex">
        <div className="bg-white dark:bg-atlas_black flex flex-col flex-1 min-h-screen lg:h-auto py-5 lg:py-10 gap-y-5">
          <div className="lg:mx-16 md:mx-16 mx-12 items-center flex justify-between">
            <Logo />
            <div>
              <Link to='/signup/individual' className="font-normal text-sm cursor-pointer text-atlas_orange transition-all ease-in duration-150 hover:brightness-90 hover:underline"
              >
                Register as individual
              </Link>
            </div>
          </div>

          <div className="flex flex-1 flex-col">
            <div className="font-sans text-white lg:px-16 md:px-16 px-12 pb-10">
              <h3 className="text-4xl text-atlas_black dark:text-white pb-2">Create Account</h3>
              <p className="font-normal text-opacity-70 text-sm text-atlas_gray dark:text-atlas_darkslate">
                Enter the following information correctly to create your Atlax
                account
              </p>
            </div>

            <form className="lg:px-16 md:px-16 px-12 font-sans space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="business_name"
                  className="text-atlas_black dark:text-white text-lg font-normal block"
                >
                  Business name
                </label>
                <div className="relative flex items-center">
                  <input
                    type="text"
                    {...getFieldProps('business_name')}
                    className={`${touched.business_name && errors.business_name ? 'border-red-600' : 'border-none'} px-3 py-3 w-full rounded-md text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                    required
                    inputMode="text"
                    placeholder="Business name"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  >
                    {touched.business_name && errors.business_name ? (
                      <FiAlertCircle className="text-red-600" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {touched.business_name && errors.business_name ?
                  <div className="flex justify-start text-sm pt-2 text-red-500">
                    {errors.business_name.toString()}
                  </div> : ""}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="text-atlas_black dark:text-white text-lg font-normal block"
                >
                  Email
                </label>
                <div className="relative flex items-center">
                  <input
                    type="email"
                    {...getFieldProps('email')}
                    className={`${touched.email && errors.email ? 'border-red-600' : 'border-none'} px-3 py-3 w-full rounded-md text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                    required
                    inputMode="email"
                    placeholder="someone@email.com"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  >
                    {touched.email && errors.email ? (
                      <FiAlertCircle className="text-red-600" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {touched.email && errors.email ?
                  <div className="flex justify-start text-sm pt-2 text-red-500">
                    {errors.email.toString()}
                  </div> : ""}
              </div>

              <div>
                <label
                  htmlFor="number"
                  className="text-atlas_black dark:text-white text-lg font-normal block"
                >
                  Phone Number
                </label>
                <div className="flex items-center bg-atlas_silver dark:bg-atlas_gray rounded-md">
                  <p className="text-atlas_black dark:text-slate-200 px-3 border-r border-slate-400">+234</p>
                  <div className="relative flex w-full items-center">
                    <input
                      type="text"
                      className={`${touched.number && errors.number ? 'border-red-600' : 'border-none'} px-3 py-3 w-full rounded-md text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                      {...getFieldProps('number')}
                      required
                      placeholder="800 00 00 000"
                      inputMode="text"
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    >
                      {touched.number && errors.number ? (
                        <FiAlertCircle className="text-red-600" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {touched.number && errors.number ?
                  <div className="flex justify-start text-sm pt-2 text-red-500">
                    {errors.number.toString()}
                  </div> : ""}
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="text-atlas_black dark:text-white text-lg font-normal block"
                >
                  Password
                </label>
                <div className="relative flex items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    {...getFieldProps('password')}
                    className={`${touched.password && errors.password ? 'border-red-600' : 'border-none'} px-3 py-3 w-full rounded-md text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                    required
                    inputMode="text"
                    placeholder="Password"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex space-x-2 items-center cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {touched.password && errors.password ? (
                      <FiAlertCircle className="text-red-600" />
                    ) : (
                      ""
                    )}
                    {showPassword ? (
                      <AiOutlineEyeInvisible className="text-black dark:text-white" color="" />
                    ) : (
                      <AiOutlineEye className="text-black dark:text-white" color="" />
                    )}
                  </div>
                </div>
                {touched.password && errors.password ?
                  <div className="flex justify-start text-sm pt-2 text-red-500">
                    {errors.password.toString()}
                  </div> : ""}
              </div>

              <div>
                <label
                  htmlFor="Confirmpassword"
                  className="text-atlas_black dark:text-white text-lg font-normal block"
                >
                  Confirm Password
                </label>
                <div className="relative flex items-center">
                  <input
                    type={showConfirm ? "text" : "password"}
                    {...getFieldProps('confirm_password')}
                    className={`${touched.confirm_password && errors.confirm_password ? 'border-red-600' : 'border-none'} px-3 py-3 w-full rounded-md text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                    required
                    inputMode="text"
                    placeholder="Confirm Password"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex space-x-2 items-center cursor-pointer"
                    onClick={() => setShowConfirm(!showConfirm)}
                  >
                    {touched.confirm_password && errors.confirm_password ? (
                      <FiAlertCircle className="text-red-600" />
                    ) : (
                      ""
                    )}

                    {showConfirm ? (
                      <AiOutlineEyeInvisible className="text-black dark:text-white" color="" />
                    ) : (
                      <AiOutlineEye className="text-black dark:text-white" color="" />
                    )}
                  </div>
                </div>
                {touched.confirm_password && errors.confirm_password ?
                  <div className="flex justify-start text-sm pt-2 text-red-500">
                    {errors.confirm_password.toString()}
                  </div> : ""}
              </div>

              <div className="flex justify-start py-2 text-red-500">
                {errorMessage}
              </div>
              {/* <<<<<<<<< form input boxes end. >>>>>>>>>>>  */}

              <section className="flex flex-col mt-0 text-slate-400 text-xs lg:text-sm font-light ">
                {/* <div className="flex items-center">
                  <label
                    className="relative flex cursor-pointer items-center rounded-full p-3"
                    data-ripple-dark="true"
                  >
                    <input
                      id="ripple-on"
                      type="checkbox"
                      className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-atlas_orange shadow hover:shadow-md transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-slate-400 before:opacity-0 before:transition-opacity checked:border-atlas_orange checked:bg-atlas_orange checked:before:bg-slate-400 hover:before:opacity-10"
                    />
                    <span className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                  <label>I am 18years of age or older</label>
                </div> */}

                {/* between the two checkbox elements */}

                <div className="flex items-center">
                  <label
                    className="relative flex cursor-pointer items-center rounded-full p-3"
                    data-ripple-dark="true"
                  >
                    <input
                      id="ripple-on"
                      type="checkbox"
                      className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-atlas_orange shadow hover:shadow-md transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-slate-400 before:opacity-0 before:transition-opacity checked:border-atlas_orange checked:bg-atlas_orange checked:before:bg-slate-400 hover:before:opacity-10"
                    />
                    <span className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </label>
                  <label className="">I agree to the user agreement and I have read the privacy policy</label>
                </div>
              </section>

              <button
                type="submit"
                disabled={loading}
                className="btn py-3 mt-4 hover:brightness-90 bg-atlas_orange">
                {loading ? <Spinner /> : "Sign Up"}
              </button>
              <div className="flex space-x-2 items-center place-content-center italic text-xs md:text-sm">
                <p className="text-atlas_gray dark:text-atlas_grey cursor-default opacity-85">Do you already have an Atlax account?</p>
                <Link to="/login" className="font-normal cursor-pointer text-atlas_orange transition-all ease-in duration-150 hover:brightness-90 hover:underline">
                  Sign In
                </Link>
              </div>
            </form>
          </div>
        </div>

        <div className="hidden lg:block bg-atlas_dark flex-1">
          <div className="flex justify-center items-start h-full">
            <SideImage />
            {/* <img src={LoginImage} alt="patterns" className=" h-full " /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPageBusiness;
