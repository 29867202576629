import React from 'react'
import { useDashboardContext } from '../../Context/DashboardContext';
import { MdOutlineShare } from "react-icons/md";
import { VscSend } from "react-icons/vsc";


const ProfilePage = () => {
  const { user } = useDashboardContext();

  return (
    <section className='flex items-center max-w-screen-xl justify-between font-manrope gap-10 p-5 lg:p-10'>
      <div className='flex flex-col self-start text-white w-1/2 rounded-2xl bg-transparent p-5'>
        <div className='flex items-center justify-between pb-8'>
          <div className='flex space-x-5'>
            <img
              className="size-16 rounded-full cursor-pointer outline-none hover:brightness-90"
              src="https://avatar.iran.liara.run/public/9"
              alt="avatar"
            />
            <ul className='flex flex-col self-center space-y-2 text-gray-100 items-start'>
              <li className='text-neutral-400'>User ID:  <b className='text-white'>12547563</b></li>
              <li className='text-neutral-400'>Verification Level:  <b className='text-white'>Unverified</b></li>
            </ul>
          </div>
          <button className='px-5 py-1 text-sm text-nowrap self-end rounded-md bg-atlas_orange hover:brightness-90'>Complete KYC</button>
        </div>
        <h4 className='font-semibold text-lg'>Personal information</h4>
        <div className='flex flex-col pt-5 space-y-5'>
          <div className='flex items-center justify-between'>
            <h5 className='brightness-50'>Full name</h5>
            <p className='capitalize text-white'>{user.first_name + " " + user.last_name}</p>
          </div>
          <div className='flex items-center justify-between'>
            <h5 className='brightness-50'>Email address</h5>
            <p className='text-white'>{user.email}</p>
          </div>
          <div className='flex items-center justify-between'>
            <h5 className='brightness-50'>Phone number</h5>
            <div className='flex space-x-2'>
              <p className='text-atlas_orange underline'>+234 00 000 0000</p>
              <button className='px-5 py-1 text-sm text-nowrap self-end rounded-md bg-atlas_orange text-white hover:brightness-90'>Change</button>
            </div>
          </div>
        </div>
      </div>

      {/* ref links section */}
      <div className='flex flex-col space-y-10 font-manrope text-white w-1/2 rounded-2xl bg-atlas_gray p-10'>
        <div className='flex flex-col space-y-3'>
          <h4 className='font-semibold text-lg'>Share the referral link</h4>
          <p className='brightness-50 pb-2'>Copy and share the link below to refer a friend.</p>
          <div className='bg-gray-50 rounded-md flex items-center justify-between p-2'>
            <p className='text-atlas_orange pl-3'>https://www.atlaxchange.com/tofunmi47</p>
            <MdOutlineShare className='size-8 cursor-pointer text-white p-1 rounded-md bg-atlas_orange hover:brightness-90' />
          </div>
        </div>

        {/* divider */}
        <div className="relative">
          <div aria-hidden="true" className="absolute inset-0 flex px-20 items-center">
            <div className="w-full border-t border-neutral-400" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-atlas_gray px-2 text-neutral-400">
              <h5>or</h5>
            </span>
          </div>
        </div>

        <div className='flex flex-col space-y-3'>
          <h4 className='font-semibold text-lg'>Invite your friends! 🤝</h4>
          <p className='brightness-50 pb-2'>input your friends email addresses and send an invitation to join.</p>
          <div className='bg-gray-50 rounded-md flex items-center justify-between p-2'>
            <input
              type='email'
              placeholder='Input Email addresses...'
              className='p-1 w-2/3 bg-transparent outline-none text-slate-900'
            />
            <VscSend className='size-8 cursor-pointer text-white p-1 rounded-md bg-atlas_orange hover:brightness-90' />
          </div>
        </div>
        <p className='brightness-75 font-thin text-lg'>
          Spread the word about our amazing services to your friends and reap the benefits!
          For every friend you refer who joins Atlaxchange, you'll
          receive a sum of N1000 as a token.
        </p>
        <ol className='brightness-75 font-thin text-lg list-decimal list-inside'>
          <li>Share your unique referral link with your friends.</li>
          <li>Your friend signs up using your link.</li>
          <li>Once they join, you both earn – they get access to our fantastic services, and you get N1000</li>
        </ol>
      </div>
    </section>
  )
}

export default ProfilePage;