import React, { useState, useEffect } from "react";
import OtpComponent from "../OtpComponent";
import { IoArrowBack } from "react-icons/io5";


interface ComponentProps {
  email: string;
  onNext: () => void;
  onPrev: () => void;
}

const Otp = ({ onNext, onPrev, email }: ComponentProps) => {
  const [timeLeft, setTimeLeft] = useState(60);

  const maskEmail = (email: string) => {
    const [localPart, domain] = email.split("@");
    if (!localPart || !domain) {
      // Not a valid email format
      return email;
    }

    // Keep the first two characters and the last character of the local part unmasked
    const maskedLocalPart = `${localPart.slice(0, 2)}${"*".repeat(
      localPart.length - 3
    )}${localPart.slice(-1)}`;
    return `${maskedLocalPart}@${domain}`;
  };

  useEffect(() => {
    // Exit early when we reach 0
    if (timeLeft === 0) return;

    // Save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <>
      <div className="font-sans lg:px-16 md:px-16 px-12 py-10">
        {/* <button
          className="bg-atlas_dark-brown rounded-full p-2"
          onClick={onPrev}
        >
          <IoArrowBack size={20} />
        </button> */}
        <h3 className="text-4xl text-atlas_black dark:text-white pb-2">OTP</h3>
        <p className="font-normal text-opacity-70 text-sm text-atlas_gray dark:text-atlas_darkslate">
          Enter the verication code sent to your email
        </p>
      </div>
      <OtpComponent
        length={6}
        className="lg:px-16 md:px-16 px-12 font-sans mb-4"
      />
      <p className=" lg:px-16 md:px-16 px-12 font-sans italic text-xs text-atlas_gray dark:text-atlas_darkslate text-opacity-70">
        Enter the 6 digit code sent to {maskEmail(email)}
      </p>
      <div className="lg:px-16 md:px-16 px-12 mt-4">
        <button
          className={`btn py-3 ${
            timeLeft === 0 ? "" : "opacity-50 cursor-not-allowed"
          }`}
          disabled={timeLeft !== 0}
          onClick={() => setTimeLeft(60)}
        >
          {timeLeft === 0
            ? "Resend code"
            : `Resend code in ${timeLeft} seconds`}
        </button>
        <button
          className={`btn py-3 mt-4`}
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Otp;
