import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "Q: What services does Atlax Exchange offer?",
    answer:
      "We provide foreign exchange, remittance, lending, payment solutions, and investment options—all on one secure platform.",
  },
  {
    question: "Q: Is my information secure with Atlax Exchange?",
    answer:
      "Absolutely. We use advanced security measures to protect your data and ensure every transaction is safe.",
  },
  {
    question: "Q: How long does a transaction take?",
    answer:
      "Our platform is designed for quick turnaround times. Most transactions are completed within minutes, though times may vary by service.",
  },
  {
    question: "Q: How do I get started?",
    answer:
      "Simply create an account, complete verification, and explore the services we offer.",
  },
];

const FAQSection: React.FC = () => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const toggleAnswer = (index: number) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  return (
    <div className="lg:mx-96 mx-10 lg:p-4 font-manrope">
      {faqData.map((item, index) => (
        <div
          key={index}
          className={`${
            openIndexes.includes(index)
              ? "border-atlas_orange border-2 border-solid"
              : "border-none"
          } p-3 mb-5 shadow-xl rounded-lg bg-atlas_grey`}
        >
          <button
            className={` w-full text-left flex justify-between space-x-5 items-center p-4 focus:outline-none`}
            onClick={() => toggleAnswer(index)}
            aria-expanded={openIndexes.includes(index)}
            aria-controls={`faq-${index}`}
          >
            <span className="w-4/5 text-lg font-medium">{item.question}</span>
            {openIndexes.includes(index) ? (
              <FaMinus className="w-8 h-8 lg:size-5 text-atlas_grey bg-atlas_black rounded-full lg:p-1 p-2" />
            ) : (
              <FaPlus className="w-8 h-8 lg:size-5 text-atlas_grey bg-atlas_black rounded-full lg:p-1 p-2" />
            )}
          </button>
          {openIndexes.includes(index) && (
            <div id={`faq-${index}`} className="px-4 pb-4 text-gray-600 font-normal">
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
