import { useContext, useState, useEffect } from "react";
import myContext from "../../Context/AppContext";
import DashboardIcon from "../../assets/dashboard";
import GroupIcon from "../../assets/Group 20";
import BriefcaseIcon from "../../assets/briefcase";
import { Link, useLocation } from "react-router-dom";
import ChartIcon from "../../assets/Convert";
import CogIcon from "../../assets/CogIcon";
import ThemeToggle from "../ThemeToggle";
import TagIcon from "../../assets/TagIcon";

const Sidebar = () => {
  const MenuListTop = [
    { name: "dashboard", icon: DashboardIcon, route: "/dashboard" },
    { name: "wallet", icon: BriefcaseIcon, route: "/dashboard/wallet" },
    { name: "loans", icon: GroupIcon, route: "/dashboard/loans" },
    { name: "convert", icon: ChartIcon, route: "/dashboard/convert" },
    // { name: "trade", icon: GroupIcon, route: "/dashboard/trade" },
  ];

  const MenuListBottom = [
    { name: "developers", icon: TagIcon, route: "/dashboard/developers" },
    { name: "settings", icon: CogIcon, route: "/dashboard/settings" },
    // { name: "settings", icon: GroupIcon, route: "/dashboard/settings" },
  ];

  const [activeNavItem, setActiveNavItem] = useState("dashboard");

  const { sidebarOpen, updateSidebarOpen, topComponentRef } =
    useContext(myContext);
  const [sidebarHeight, setSidebarHeight] = useState("100vh");

  const handleItemClick = (itemName: any) => {
    setActiveNavItem(itemName);
    updateSidebarOpen(false);
  };

  const location = useLocation();

  useEffect(() => {
    const adjustSidebarHeight = () => {
      const topComponentHeight = topComponentRef.current?.offsetHeight || 0;
      setSidebarHeight(`calc(100vh - ${topComponentHeight}px)`);
    };

    // Set activeNavItem based on the current route
    const currentPath = location.pathname.split("/")[2]; // Assuming the structure is /dashboard/[name]
    if (currentPath && MenuListTop.concat(MenuListBottom).some(item => item.route.endsWith(currentPath))) {
      setActiveNavItem(currentPath);
    } else {
      setActiveNavItem("dashboard"); // Fallback to dashboard
    }

    // Adjust sidebar height on mount and window resize
    adjustSidebarHeight();
    window.addEventListener("resize", adjustSidebarHeight);

    return () => window.removeEventListener("resize", adjustSidebarHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, topComponentRef]);

  return (
    <div className="flex">
      {/* Overlay */}
      {sidebarOpen && (
        <div
          className="fixed bg-black bg-opacity-50 z-10 lg:hidden"
          onClick={() => updateSidebarOpen(false)}
        ></div>
      )}

      {/* Sidebar */}
      <div
        className={`fixed border-r border-atlas_gray left-0 transform ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
          } w-10/12 md:w-3/12 bg-white dark:bg-atlas_dark text-white transition-transform duration-300 ease-in-out z-20 lg:translate-x-0 lg:inset-auto lg:w-60`}
      >
        {/* Sidebar content */}

        <nav className="flex flex-col justify-between pb-10 lg:pb-5" style={{ height: sidebarHeight }}>
          {/* Your nav items here */}
          <ul className="capitalize border-b border-atlas_gray ">
            {MenuListTop.map((item, index) => (
              <Link key={index} to={item.route}>
                <li
                  key={index}
                  className={`flex items-center my-5 md:my-2 ml-5 pl-4 py-2 cursor-pointer rounded-l-xl hover:bg-gray-300 hover:bg-opacity-10 ${activeNavItem === item.name
                    ? "bg-white text-black rounded-l-xl transition-transform duration-700 ease-in-out"
                    : "bg-transparent text-white"
                    }`}
                  onClick={() => handleItemClick(item.name)}
                >
                  <div className="w-8 h-8 rounded-full flex items-center justify-center mr-2">
                    <item.icon
                      className={`w-5 h-5`}
                      strokeColor={activeNavItem === item.name ? "#FFFFFF" : "#1E1E1E"}
                      fillColor={activeNavItem === item.name ? "#000000" : "#FFFFFF"}
                    />{" "}
                  </div>
                  {item.name}
                </li>
              </Link>
            ))}
          </ul>

          <ul className="capitalize pt-5">
            {MenuListBottom.map((item, index) => (
              <Link to={item.route} key={index}>
                <li
                  key={index}
                  className={`flex items-center my-5 md:my-2 ml-5 pl-4 py-2 cursor-pointer rounded-l-xl hover:bg-gray-300 hover:bg-opacity-10 ${activeNavItem === item.name
                    ? "bg-white text-black rounded-l-xl transition-transform duration-700 ease-in-out"
                    : "bg-transparent text-white"
                    }`}
                  onClick={() => handleItemClick(item.name)}
                >
                  <div className="w-8 h-8 rounded-full flex items-center justify-center mr-2">
                    <item.icon
                      className={`w-5 h-5 `}
                      fillColor={activeNavItem === item.name ? "#000000" : "#FFFFFF"}
                    />{" "}
                  </div>
                  {item.name}
                </li>
              </Link>
            ))}
            <div className="flex flex-col items-center ml-5">
              <ThemeToggle />
            </div>
          </ul>
        </nav>
      </div>

      {/* Page content */}
    </div>
  );
};

export default Sidebar;
