import { useEffect } from "react";

interface ResendCounterProps {
  counter: number;
  setCounter: (counter: number) => void;
}

const ResendCounter = ({ counter, setCounter }: ResendCounterProps) => {
  
  useEffect(() => {
    let interval: any;
    if (counter !== null && counter > 0) {
      interval = setInterval(() => {
        const newValue = counter <= 0 ? counter : counter - 1;
        // TODO: i'm not sure if this is the best way of doing this.
        localStorage.setItem("counter", newValue.toString());
        setCounter(newValue);
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (counter <= 0) {
        localStorage.removeItem("counter");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCounter, counter]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return <div className={`${counter <= 0 ? "hidden" : "block" }`}>{' '} {formatTime(counter)}</div>;
};

export default ResendCounter;
