import { useContext } from "react";
import logo from "../../assets/Logo.svg";
import { IoIosNotificationsOutline } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import { HiChevronDown, HiChevronRight } from "react-icons/hi2";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import myContext from "../../Context/AppContext";
import { MdOutlineClose } from "react-icons/md";
import { useDashboardContext } from "../../Context/DashboardContext";
import LightLogo from "../LightLogo";

const Header = () => {
  const { user } = useDashboardContext();
  const { sidebarOpen, updateSidebarOpen, topComponentRef } =
    useContext(myContext);
  return (
    <div
      ref={topComponentRef}
      className="flex justify-between items-center font-manrope py-4 px-5 border-b border-atlas_gray sticky top-0 bg-atlas_dark z-10"
    >
      {/* <div className="flex lg:hidden items-center space-x-5 justify-between">
        <div className="flex items-center justify-between space-x-3">
          <img
            className="w-8 h-8 rounded-full cursor-pointer outline outline-offset-2 outline-2 outline-neutral-600 hover:outline-offset-0 hover:brightness-90"
            src="https://avatar.iran.liara.run/public/9"
            alt="avatar"
          />
          <p className="text-white font-manrope text-nowrap text-center capitalize cursor-default font-normal text-base">
            {user.first_name + " " + user.last_name}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <div className="shadow-md scale-75 rounded-md pr-2 bg-atlas_orange cursor-pointer brightness-90 active:brightness-95">
            <select
              id="banking"
              className="block w-full rounded-md bg-atlas_orange py-1 pl-2 pr-2 text-white outline-none cursor-pointer text-base"
            >
              <option className="bg-neutral-700">Fiat banking</option>
              <option className="bg-neutral-700">Crypto</option>
              <option className="bg-neutral-700">Placeholder</option>
            </select>
          </div>
          <IoIosNotificationsOutline className="text-white bg-neutral-700 rounded-full w-6 h-6 cursor-pointer hover:brightness-125" />
        </div>
      </div> */}
      <div className="hidden lg:flex items-center space-x-24 justify-between">
        <LightLogo />
        <div className="inline-flex items-baseline justify-between cursor-pointer hover:underline hover:brightness-90 text-white">
          <h3 className="text-sm">Home</h3>
          <HiChevronRight className="size-3 translate-y-[1.5px]" />
        </div>
      </div>
      <div className="flex flex-row-reverse lg:flex-row  justify-between items-center">
        <div className="mr-5 shadow-md rounded-md pr-2 bg-atlas_orange cursor-pointer brightness-90 hover:brightness-95">
          <select
            id="banking"
            className="block w-full rounded-md bg-atlas_orange py-1 pl-2 pr-2 text-white outline-none cursor-pointer text-sm"
          >
            <option className="bg-neutral-700">Fiat banking</option>
            <option className="bg-neutral-700">Crypto</option>
            <option className="bg-neutral-700">Placeholder</option>
          </select>
        </div>
        <IoIosNotificationsOutline className="text-white bg-neutral-700 rounded-full w-6 h-6 cursor-pointer hover:brightness-125 mr-5" />
        <div className="flex flex-row-reverse lg:flex-row items-center">
          <p className="text-white font-manrope pl-3 lg:pl-0 pr-3 text-center capitalize cursor-default font-normal text-sm">
            {user.first_name + " " + user.last_name}
          </p>
          <img
            className="w-8 h-8 rounded-full cursor-pointer outline outline-offset-2 outline-2 outline-neutral-600 hover:outline-offset-0 hover:brightness-90"
            src="https://avatar.iran.liara.run/public/9"
            alt="avatar"
          />
        </div>
      </div>

      {/* Hamburger menu: button and toggle */}
      <div className="lg:hidden relative">
        <div
          className={`absolute inset-0 flex items-center justify-end transition-opacity duration-300 ${sidebarOpen ? "opacity-0" : "opacity-100"
            }`}
        >
          <button
            className=" text-white p-2"
            onClick={() => updateSidebarOpen(!sidebarOpen)}
          >
            {<RxHamburgerMenu size={30} />}
          </button>
        </div>

        <div
          className={`absolute inset-0 flex items-center justify-end transition-opacity duration-300 ${sidebarOpen ? "opacity-100" : "opacity-0"
            }`}
        >
          <button
            className="text-white p-2"
            onClick={() => updateSidebarOpen(!sidebarOpen)}
          >
            <MdOutlineClose size={30} />
          </button>
        </div>
      </div>
      {/* Your page content here */}
    </div>
  );
};

export default Header;
