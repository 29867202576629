import Logo from "../assets/homepageLogo.svg";
import { Link } from "react-router-dom";


function HomeLogo() {
  return (
    <>
      <Link to="/">
        <img className="" src={Logo} alt="logo" />
      </Link>
    </>
  )
}

export default HomeLogo;